import { Component } from "react"
import "./App.css"

/*
Y0,O0,G1,B0
G0,O1,B0,Y1
G1,Y0,B1,O0
Y0,G1,G0,O0
G1,Y1,B0,O1
B1,Y0,G0,O0
Y1,O1,Y1,B0
G1,B0,O0,Y1
O0,B1,G0,B1
*/
const tiles = [
  [0, 1, 49, 3],
  [2, 50, 3, 51],
  [49, 0, 48, 1],
  [0, 49, 2, 1],
  [49, 51, 3, 50],
  [48, 0, 2, 1],
  [51, 3, 51, 50],
  [49, 3, 1, 51],
  [1, 48, 2, 48],
]

class App extends Component {
  state = {
    board: [0, 4, 8, 12, 16, 20, 24, 28, 32],
    baseTime: 300,
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve))

  sleep = (time) => new Promise((resolve) => setTimeout(resolve, time))

  isValidBoard = (tileNum, boardId, rot) => {
    const currentTile = tiles[tileNum]
    // if tile is not in top row
    if (boardId >= 3) {
      // check if top edge of current and bottom edge of top tile match
      const currentTileTopEdge = currentTile[rot]
      const topTile = this.state.board[boardId - 3]
      const topTileBottomEdge = tiles[topTile >> 2][(topTile + 2) & 3]
      if (currentTileTopEdge + topTileBottomEdge !== 51) {
        return false
      }
    }
    // if tile is not in left column
    if (boardId % 3 !== 0) {
      // check if left edge of current and right edge of left tile match
      const currentTileLeftEdge = currentTile[(rot + 3) & 3]
      const leftTile = this.state.board[boardId - 1]
      const leftTileRightEdge = tiles[leftTile >> 2][(leftTile + 1) & 3]
      if (currentTileLeftEdge + leftTileRightEdge !== 51) {
        return false
      }
    }
    return true
  }

  backtrack = async (tilesMask, boardId) => {
    if (boardId === 9) {
      await this.sleep(900)
      alert("Solution Found!")
      await this.setState({ baseTime: 0 })
      return
    }
    for (let tileNum = 0; tileNum < 9; ++tileNum) {
      await this.sleep(this.state.baseTime / 2)
      if (((tilesMask >> tileNum) & 1) === 0) {
        let newBoard = [...this.state.board]
        for (let rot = 0; rot < 4; ++rot) {
          await this.sleep(this.state.baseTime)
          newBoard[boardId] = (tileNum << 2) | rot
          await this.promisedSetState({ board: newBoard })
          if (this.isValidBoard(tileNum, boardId, rot)) {
            await this.backtrack(tilesMask | (1 << tileNum), boardId + 1)
          }
          if (boardId === 4) {
            break
          }
        }
        await this.sleep(this.state.baseTime)
        newBoard[boardId] = -1
        await this.promisedSetState({ board: newBoard })
      }
    }
  }

  solve = async () => {
    await this.promisedSetState({ board: [-1, -1, -1, -1, -1, -1, -1, -1, -1] })
    this.backtrack(0, 0)
  }

  render() {
    return (
      <div className="container">
        <div className="board">
          {this.state.board.map((slot, i) => {
            let styles = {
              opacity: 0,
            }
            if (slot !== -1) {
              styles = {
                opacity: 1,
                backgroundImage: `url("/tiles/${slot >> 2}.webp")`,
                transform: `rotate(${(slot & 3) * -90}deg)`,
              }
            }
            return (
              <div key={i} style={styles}>
                {slot !== -1 ? (slot >> 2) + 1 : ""}
              </div>
            )
          })}
        </div>
        <div className="btns">
          <button className="btn btn-1" onClick={this.solve}>
            Solve
          </button>
        </div>
      </div>
    )
  }
}

export default App
